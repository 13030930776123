<template>
  <b-card>
    <b-row>
      <b-col cols="12"
             class="d-flex align-items-center"
             style="gap:10px;"
      >
        <h5>{{ $t('chat-page.select-input-type') }}</h5>
        <div v-if="!disableInputOption"
             class="d-flex input-selection-types"
        >
          <div v-b-tooltip.hover="$t('chat-page.input-type-text')"
               class="input-selection-card"
               :class="inputType=='text' && 'selected'"
               @click="inputType='text'"
          >
            <feather-icon icon="TypeIcon"
                          size="20"
            />
          </div>
          <div v-b-tooltip.hover="$t('chat-page.input-type-audio')"
               class="input-selection-card"
               :class="inputType=='audio' && 'selected'"
               @click="inputType='audio'"
          >
            <feather-icon icon="MicIcon"
                          size="20"
            />
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="showLangSelector"
           class="my-2"
    >
      <b-col cols="12"
             class="d-flex align-items-center"
      >
        <h5 class="mr-2">
          {{ $t('chat-page.select-input-language') }}:
        </h5>
        <b-form-select v-model="selectedInputLanguage"
                       :options="languageOptions"
        />
      </b-col>
      <b-col cols="12"
             class="d-flex align-items-center"
      >
        <h5 class="mr-2">
          {{ $t('chat-page.select-output-language') }}:
        </h5>
        <b-form-select v-model="selectedLanguage"
                       :options="languageOptions"
        />
      </b-col>
    </b-row>

    <b-row class="my-2">
      <b-col cols="12"
             class="d-flex align-items-center"
      >
        <h5 class="mr-2">
          {{ $t('chat-page.select-prompt') }}:
        </h5>
        <b-overlay :show="isFetchingPrompt">
          <b-form-select v-model="selectedPrompt"
                         :options="promptOptionLists"
                         value-field="id"
                         text-field="name"
                         @change="updatePrompt"
          />
        </b-overlay>
      </b-col>
    </b-row>

    <h5 class="my-1">
      {{ $t('chat-page.please-select-your-character') }}.
    </h5>
    <b-row>
      <b-col
        v-for="(character,key) in characters "
        :key="key"
        lg="4"
        sm="6"
      >
        <div
          class="character-image d-flex justify-content-center align-items-center"
          :class="{'selected-character':key===selectedCharacter}"
        >
          <b-form-radio
            v-model="selectedCharacter"
            name="selected-character"
            class="p-0"
            style="margin:5px;"
            :disabled="isProcessing"
            :value="key"
          >
            <img :src="`${require(`@/assets/images/chatbot/${character}`)}`"
                 class="img-fluid"
            >
          </b-form-radio>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { IDLE } from '@/const/chatbot'
import {
  BRow, BCol, BFormRadio, BCard, BFormSelect, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'

export default {
  components: {
    BRow,
    BCol,
    BFormRadio,
    BCard,
    BFormSelect,
    BOverlay,
  },
  props: {
    disableInputOption: {
      type: Boolean,
      default: false,
    },
    showLangSelector: {
      type: Boolean,
      default: false,
    },
    initialPrompt: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      characters: IDLE,
      isProcessing: false,
      languageOptions: [
        { value: null, text: i18n.tc('chat-page.select-language') },
        { value: 'english', text: i18n.tc('chat-page.english') },
        { value: 'korean', text: i18n.tc('chat-page.korean') },
        { value: 'japanese', text: i18n.tc('chat-page.japanese') },
        { value: 'vietnamese', text: i18n.tc('chat-page.vietnamese') },
        { value: 'chinese', text: i18n.tc('chat-page.chinese') },
        { value: 'turkish', text: i18n.tc('chat-page.turkish') },
        { value: 'nepali', text: i18n.tc('chat-page.nepali') },
      ],
      promptOptionLists: [],
      selectedPrompt: this.initialPrompt,
      isFetchingPrompt: false,
    }
  },
  computed: {
    selectedCharacter: {
      get() {
        return this.$store.getters['appConfig/currentChatbotCharacter']
      },
      set(val) {
        this.$store.commit('appConfig/SET_CURRENT_CHATBOT_CHARACTER', val)
      },
    },
    inputType: {
      get() {
        return this.$store.getters['appConfig/currentChatbotInputType']
      },
      set(val) {
        this.$store.commit('appConfig/SET_CURRENT_CHATBOT_INPUT_TYPE', val)
      },
    },
    selectedLanguage: {
      get() {
        return this.$store.getters['appConfig/currentChatbotLanguage']
      },
      set(val) {
        this.$store.commit('appConfig/SET_CURRENT_CHATBOT_LANGUAGE', val)
      },
    },
    selectedInputLanguage: {
      get() {
        return this.$store.getters['appConfig/currentChatbotInputLanguage']
      },
      set(val) {
        this.$store.commit('appConfig/SET_CHATBOT_INPUT_LANGUAGE', val)
      },
    },
    activeView() {
      return this.$store.state.studentLab.activeViewOfLab || 'statement'
    },
  },
  watch: {
    activeView() {
      this.getAllPrompts()
    },
  },
  mounted() {
    this.getAllPrompts()
  },
  methods: {
    updatePrompt() {
      if (this.selectedPrompt) {
        useJwt.getAiIdeProcessDetail(this.selectedPrompt).then(response => {
          const view = this.activeView
          const { data } = response.data
          this.$emit('updatePrompt', view, data)
        })
      }
    },
    getAllPrompts() {
      this.isFetchingPrompt = true
      const view = this.activeView
      const { activeProblem } = this.$store.state.problem
      useJwt.getLessonPromptOfSpecificView(activeProblem.lesson_info.id, {
        params: {
          view,
        },
      }).then(response => {
        this.promptOptionLists = response.data.data.data
        this.selectedPrompt = this.initialPrompt || response.data.data.active
      }).finally(() => {
        this.isFetchingPrompt = false
      })
    },
  },
}
</script>

<style lang="scss">
  .input-selection-types{
    align-items: center;
    justify-content: center;
    gap:15px;

    .input-selection-card{
      width:35px;
      height:35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #7367f0;
      cursor: pointer;
      color:#7367f0;
      border-radius: 5px;
      &.selected{
        background: #7367f0;
        border-color: #7367f0;
        color:white;
      }
    }
  }
</style>
