<template>
  <div :id="id"
       :style="`width:${width}px; height:${height}px; bottom:${bottom}; left:${leftPos}; top:${topPos}; right:${right}; position:fixed; cursor:grabbing; z-index: 1032; touch-action: none; `"
       draggable="false"
       @click="removeDrag"
  >
    <slot />
  </div></template>
<script>
export default {
  props: {
    height: {
      type: Number,
      default: 100,
    },
    width: {
      type: Number,
      default: 100,
    },
    bottom: {
      type: [Number, String],
      default: '5%',
    },
    left: {
      type: [Number, String],
      default: '1%',
    },
    top: {
      type: [Number, String],
      default: 'auto',
    },
    right: {
      type: [Number, String],
      default: 'auto',
    },
    id: {
      type: String,
      default: '',
    },
    draggableSection: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isMouseDown: false,
      isTouchDown: false,
      leftPos: this.left,
      topPos: this.top,
      initX: window.botCurrentX || null,
      initY: window.botCurrentY || null,
    }
  },
  mounted() {
    setTimeout(() => {
      this.setupDrag()
    }, 1500)
    document.addEventListener('keydown', this.handleEscKey)
  },
  beforeDestroy() {
    window.botCurrentX = this.initX
    window.botCurrentY = this.initY
    document.removeEventListener('keydown', this.handleEscKey)
  },
  methods: {
    handleEscKey(e) {
      if (e.key === 'Escape') {
        this.removeDrag()
      }
    },
    setupDrag() {
      document.getElementById(this.draggableSection || this.id).addEventListener('mousedown', this.setMouseDown)
      document.getElementById(this.draggableSection || this.id).addEventListener('touchstart', this.setTouchDown)
      document.getElementById(this.draggableSection || this.id).addEventListener('mouseup', this.setMouseUp)
      document.getElementById(this.draggableSection || this.id).addEventListener('touchend', this.setMouseUp)
      document.addEventListener('mousemove', this.setMouseDrag)
      document.addEventListener('touchmove', this.setMouseDrag)
    },
    setMouseDrag(e) {
      let clientX
      let clientY
      if (this.isMouseDown || this.isTouchDown) {
        if (this.isMouseDown) {
          clientX = e.clientX
          clientY = e.clientY
        } else if (this.isTouchDown) {
          clientX = e.touches[0].clientX
          clientY = e.touches[0].clientY
        }

        let cx = clientX - this.initX
        let cy = clientY - this.initY
        if (cx < 0) {
          cx = 0
        }
        if (cy < 0) {
          cy = 0
        }
        if (window.innerWidth - clientX + this.initX < this.width) {
          cx = window.innerWidth - this.width
        }
        if (clientY > window.innerHeight - this.height + this.initY) {
          cy = window.innerHeight - this.height
        }
        document.getElementById(this.id).style.left = `${cx}px`
        document.getElementById(this.id).style.top = `${cy}px`
        this.leftPos = `${cx}px`
        this.topPos = `${cy}px`
      }
    },
    removeDrag() {
      if (this.isMouseDown || this.isTouchDown) {
        this.setMouseUp()
      }
    },
    setMouseDown(e) {
      this.isMouseDown = true
      document.body.classList.add(this.id)
      this.initX = e.offsetX
      this.initY = e.offsetY
    },

    setTouchDown(e) {
      this.isTouchDown = true
      document.body.classList.add(this.id)
      this.initX = e.target.offsetWidth
      this.initY = e.target.offsetHeight
    },
    setMouseUp() {
      this.isMouseDown = false
      this.isTouchDown = false
      document.body.classList.remove(this.id)
    },
  },
}
</script>
