<template>
  <div>
    <div v-if="inputType=='text'"
         class="chat-app-form"
    >
      <div
        role="group"
        class="input-group input-group-merge form-send-message mr-1"
      >
        <input
          v-model="typedMsg"
          type="text"
          :placeholder="$t('chat-page.enter-your-message')"
          class="form-control"
          @keyup.enter="sendMessage"
        >
      </div>
      <button data-v-5e8ea5c2=""
              type="button"
              class="btn btn-primary"
              :disabled="!typedMsg || isProcessing || isPlaying"
              @click="sendMessage"
      >
        <b-spinner
          v-if="isProcessing"
          small
          type="grow"
        /><span v-else>{{ $t("send-notification-module.send") }}</span>
      </button>
    </div>
    <b-overlay v-else
               :show="isProcessing || isPlaying"
    >
      <b-card body-class="p-0">

        <speak-with-bot :auto-stop="true"
                        @textAudio="sendMessage"
        />
      </b-card>
    </b-overlay>

  </div>
</template>
<script>
import { BSpinner, BCard, BOverlay } from 'bootstrap-vue'
import SpeakWithBot from './SpeakWithBot.vue'

export default {
  components: {
    BSpinner,
    SpeakWithBot,
    BCard,
    BOverlay,
  },
  props: {
    isProcessing: {
      type: Boolean,
      default: false,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typedMsg: '',
    }
  },
  computed: {
    inputType() {
      return this.$store.getters['appConfig/currentChatbotInputType']
    },
  },
  methods: {
    sendMessage(val = null) {
      this.$emit('sendMessage', val.text || this.typedMsg)
      this.typedMsg = ''
    },
  },
}
</script>
