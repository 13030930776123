<template>
  <b-modal
    :visible="true"
    title="Pick a Set"
    no-close-on-backdrop
    centered
    :hide-footer="!canAbortLesson"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-overlay :show="isProcessing">
      <b-row>
        <b-col
          v-for="set of sets"
          :key="set.set_no"
          class="text-center"
          sm="6"
        >
          <b-card
            class="ecommerce-card"
            :class="{
              'border-success': attemptedSets.includes(set.set_id)
            }"
            no-body
            role="button"
            @click="!attemptedSets.includes(set.set_id) ? updateStudentSets(set.set_id) : null"
          >
            <b-img
              class="card-img-top"
              :src="set.set.image"
              height="100"
              width="100"
            />
            <b-card-body
              v-if=" set.set"
              class="text-center"
            >
              <div class="truncate pt-1">
                <feather-icon
                  v-if="attemptedSets.includes(set.set_id)"
                  icon="CheckCircleIcon"
                  style="color: green;zoom: 2;margin-bottom: 4px;"
                />
                <h2 class="mb-25 font-weight-bolder">
                  {{ set.set.name }}
                </h2>
                <p>
                  {{ set.set.description }}
                </p>
                <h3>
                  {{ set.groups_count }} <small>Problems</small>
                </h3>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <!-- Custom footer with abort button -->
    <template v-if="canAbortLesson"
              #modal-footer
    >
      <div class="w-100 text-right">
        <b-button variant="danger"
                  @click="confirmAbort"
        >
          Abort
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import {
  BCard, BRow, BCol, BOverlay, BCardBody, BImg, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BCardBody,
    BImg,
    BButton,
  },
  inject: ['handleLessonAbort'],
  props: {
    setsData: {
      type: Object,
      required: true,
    },
    lessonId: {
      type: [Number, Object],
      required: true,
    },
    classId: {
      type: [Number, Object],
      required: true,
    },
  },
  data() {
    return {
      isProcessing: false,
    }
  },
  computed: {
    sets() {
      return this.setsData.sets
    },
    attemptedSets() {
      return this.setsData?.attempted_sets || []
    },
    canAbortLesson() {
      return !this.$store.getters['studentLab/isAClassTest'] && this.$store.getters['studentLab/canAbortLesson']
    },
  },
  methods: {
    getProblem() {
      this.$emit('close')
      this.$emit('getProblem')
    },
    updateStudentSets(setId) {
      if (this.isProcessing) {
        return
      }
      this.isProcessing = true
      useJwt.updateStudentProblemSets({
        class_id: this.classId,
        lesson_id: this.lessonId,
        set_id: setId,
      }).then(() => {
        this.getProblem()
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    confirmAbort() {
      this.$swal.fire({
        title: this.$t('abort.title'),
        text: this.$t('abort.lesson-abort-text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.$t('abort.cancel'),
        confirmButtonText: this.$t('abort.confirm'),
      }).then(result => {
        if (result.isConfirmed) {
          this.handleLessonAbort()
          location.reload()
        }
      })
    },
  },
}
</script>
<style scoped>
.card:hover {
  box-shadow: 0 8px 32px 0 rgba(28, 34, 112, 0.37);
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
}
::v-deep .close {
  display: none !important;
}
</style>
